import { Pie } from 'react-chartjs-2'
import React, {useState, useEffect} from 'react'
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Chart as ChartJS , RadialLinearScale,ArcElement } from "chart.js";
import CameraSvg from '../../svgs/map/Camera';
import WatchSvg from '../../svgs/map/Watch';
import LockSvg from '../../svgs/map/Lock';
import ConfinedSpaceSvg from '../../svgs/map/ConfinedSpace';
import DangerZoneSvg from '../../svgs/map/DangerZone';
import { DEVICE_COLOR_DICT } from '../../config/style.config';
import { DEVICE_TYPE } from '../../dto/devices';
import { handleFilterDate } from '../../actions/actionsDateTime';
import { fetchEventStatsByType } from '../../services/watches.service';
import {isHideDangerZone,isHideConfinedSpace, isHideSmartLock, isHideSmartCamera, CMP_DEFAULT_FILTER} from '../../config'
//
ChartJS.register(RadialLinearScale,ArcElement);


const cache = new Map();
const colors = [
  DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA],
  DEVICE_COLOR_DICT[DEVICE_TYPE.WATCH],
  DEVICE_COLOR_DICT[DEVICE_TYPE.LOCK],
  DEVICE_COLOR_DICT[DEVICE_TYPE.DANGER_ZONE],
  DEVICE_COLOR_DICT[DEVICE_TYPE.CONFINED_SPACE],
  DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA],
];

const options={
    plugins: {
      legend: false,
      tooltip: false,
    },
  
    elements: {
      arc: {
        backgroundColor: function(context) {
          let c = colors[context.dataIndex];
          if (!c) {
            return;
          }
          return createRadialGradient3(context, c);
        },
        borderColor: function(context) {
            return colors[context.dataIndex];
        }
        
      }
    },
    
  }


  function createRadialGradient3(context,c) {
    const chartArea = context.chart.chartArea;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
  
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (width !== chartWidth || height !== chartHeight) {
      cache.clear();
    }
    let gradient = cache.get(c);
    if (!gradient) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      var width = chartWidth;
      var height = chartHeight;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const r = Math.min(
        (chartArea.right - chartArea.left) / 2,
        (chartArea.bottom - chartArea.top) / 2
      );
      const ctx = context.chart.ctx;
      const diff= c===colors[0]?.0:c===colors[2]?.13:c===colors[3]?.16:c===colors[4]?.19:.1
      gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
      gradient.addColorStop(0, `white`);
      gradient.addColorStop(.1, `white`);
      gradient.addColorStop(0.1, `${c}00`);
      gradient.addColorStop(0.3, `${c}10`);
      gradient.addColorStop(0.65-diff, `${c}70`);
      gradient.addColorStop(0.65-diff, `${c}00`);
      gradient.addColorStop(0.9, `${c}50`);
      gradient.addColorStop(1, `${c}`);
      cache.set(c, gradient);
    }
  
    return gradient;
  }




function Component(props){
    const [pieData, setPieData] = useState(undefined)
    const [devideBy, setDevideBy] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();
    const filterDate = (searchParams.get('date') || (props.isHome?CMP_DEFAULT_FILTER:'Today') )
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    useEffect(() => {
      fetchData()
      let timer = undefined
      if(props.isHome) timer = setInterval(() => fetchData(), 30000)
      return () => {
        if(timer) clearInterval(timer)
      }
    },[filterDate, filterFrom, filterTo])

    async function fetchData(){
        // if(props.alertData){
        //     console.log(props.alertData)
        //     setPieData(dataMaker(props.alertData))
        //     return;
        // }
        const filter= {}
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
        filter['projectId'] = props.control.projectId
        const test = await fetchEventStatsByType(props.token.token, {...filter,"type":{"$nin":["LOCK_LOCK", "LOCK_UNLOCK"]}},{limit:999})
        const dataForPie = [0,0,0,0,0]
        test.result?.forEach(({type}, idx) => {
          const newType = type.split('_')[0]
          if(newType === 'CAMERA') dataForPie[0]++
          if(newType === 'WATCH') dataForPie[1]++
          if(newType === 'LOCK') dataForPie[2]++
          if(newType === 'DANGER') dataForPie[3]++
          if(newType === 'CS') dataForPie[4]++
        })
        if(dataForPie.every(num => num === 0)) dataForPie.push(1)
        setPieData(dataMaker(dataForPie))
    }
    function dataMaker(dataArr){
      const data = {
        labels: ['111','222','333','444','555','666','777','888'],
        datasets: [{
            data: dataArr
        }],
      };
      return data
    }
    return(
      <div className='w-full flex flex-col text-white'>
        <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{borderBottom:'.5px solid #aaa'}}>
            <div className="text-[16px] font-[300]">Status</div>
        </div>
        {pieData && <div className="flex flex-row items-center w-full py-[16px]" style={{gap:20}}>
                <div className="w-[140px] h-[140px]">
                    <Pie width={140} height={140} data={pieData} options={options}/>
                </div>
                <div className="flex flex-col flex-1 justify-center items-stretch gap-[8px]">
                 <div className="flex flex-col flex-1 justify-center items-stretch gap-[8px]">
                  
                  {isHideSmartCamera||<div className="flex flex-row w-full">
                      <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[0]}}><CameraSvg width={14}/></div>
                      <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                          <div className="ml-2">AI Camera</div>
                          <div>{pieData?.datasets[0]?.data?.[0]} ({Math.floor(100*pieData?.datasets[0]?.data?.[0]/(pieData?.datasets[0]?.data?.[0]+pieData?.datasets[0]?.data?.[1]+pieData?.datasets[0]?.data?.[2]+pieData?.datasets[0]?.data?.[3]+pieData?.datasets[0]?.data?.[4])) || 0}%)</div>
                      </div>
                  </div>}

                  <div className="flex flex-row w-full">
                      <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[1]}}><WatchSvg width={14}/></div>
                      <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                          <div className="ml-2">Smart Watch</div>
                          <div>{pieData?.datasets[0]?.data?.[1]} ({Math.floor(100*pieData?.datasets[0]?.data?.[1]/(pieData?.datasets[0]?.data?.[0]+pieData?.datasets[0]?.data?.[1]+pieData?.datasets[0]?.data?.[2]+pieData?.datasets[0]?.data?.[3]+pieData?.datasets[0]?.data?.[4])) || 0}%)</div>
                      </div>
                  </div>

                  {isHideSmartLock||<div className="flex flex-row w-full">
                      <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[2]}}><LockSvg width={14}/></div>
                      <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                          <div className="ml-2">Smart Lock</div>
                          <div>{pieData?.datasets[0]?.data?.[2]} ({Math.floor(100*pieData?.datasets[0]?.data?.[2]/(pieData?.datasets[0]?.data?.[0]+pieData?.datasets[0]?.data?.[1]+pieData?.datasets[0]?.data?.[2]+pieData?.datasets[0]?.data?.[3]+pieData?.datasets[0]?.data?.[4])) || 0}%)</div>
                      </div>
                  </div>}

                  {isHideDangerZone||<div className="flex flex-row w-full">
                      <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[3]}}><DangerZoneSvg width={14}/></div>
                      <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                          <div className="ml-2">Danger Zone</div>
                          <div>{pieData?.datasets[0]?.data?.[3]} ({Math.floor(100*pieData?.datasets[0]?.data?.[3]/(pieData?.datasets[0]?.data?.[0]+pieData?.datasets[0]?.data?.[1]+pieData?.datasets[0]?.data?.[2]+pieData?.datasets[0]?.data?.[3]+pieData?.datasets[0]?.data?.[4])) || 0}%)</div>
                      </div>
                  </div>}

                  {isHideConfinedSpace||<div className="flex flex-row w-full">
                      <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[4]}}><ConfinedSpaceSvg width={14}/></div>
                      <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                          <div className="ml-2">Confined Space</div>
                          <div>{pieData?.datasets[0]?.data?.[4]} ({Math.floor(100*pieData?.datasets[0]?.data?.[4]/(pieData?.datasets[0]?.data?.[0]+pieData?.datasets[0]?.data?.[1]+pieData?.datasets[0]?.data?.[2]+pieData?.datasets[0]?.data?.[3]+pieData?.datasets[0]?.data?.[4])) || 0}%)</div>
                      </div>
                  </div>}

              </div>
                    {/* <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[0], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[0]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Draft</div>
                            <div>{pieData.datasets[0].data?.[0]} ({Math.floor(100*pieData.datasets[0].data?.[0]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[1], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[1]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Pending</div>
                            <div>{pieData.datasets[0].data?.[1]} ({Math.floor(100*pieData.datasets[0].data?.[1]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[2], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[2]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Approved</div>
                            <div>{pieData.datasets[0].data?.[2]} ({Math.floor(100*pieData.datasets[0].data?.[2]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[3], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[3]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Valid</div>
                            <div>{pieData.datasets[0].data?.[3]} ({Math.floor(100*pieData.datasets[0].data?.[3]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[4], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[4]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Expired</div>
                            <div>{pieData.datasets[0].data?.[4]} ({Math.floor(100*pieData.datasets[0].data?.[4]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[5], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[5]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Cancelled</div>
                            <div>{pieData.datasets[0].data?.[5]} ({Math.floor(100*pieData.datasets[0].data?.[5]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div> */}
                </div>
        </div>}
      </div>
    )
}

export default connect((store) => {
  return {
    token: store.tokenReducer,
    control: store.controlReducer,
  }
})(Component)